import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const EditIllustrationPropertyDetailsRow = ({
  postcode,
  region,
  residenceStatus,
  valuedAt,
}) => {
  const valuedAtRow = () => {
    if (valuedAt) {
      return (
        <tr>
          <td className={cx("tableCell")}>Property has been valued at</td>
          <td className={cx("tableCell")}>{valuedAt}</td>
        </tr>
      );
    }
  };

  return (
    <>
      <tr>
        <td className={cx("tableCell")}>Postcode of property</td>
        <td className={cx("tableCell")}>
          <address>{postcode}</address>
        </td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Region</td>
        <td className={cx("tableCell")}>{region}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Residence status</td>
        <td className={cx("tableCell")}>{residenceStatus}</td>
      </tr>
      {valuedAtRow()}
    </>
  );
};

EditIllustrationPropertyDetailsRow.propTypes = {
  postcode: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  residenceStatus: PropTypes.string.isRequired,
  valuedAt: PropTypes.string,
};

export default EditIllustrationPropertyDetailsRow;

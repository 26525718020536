import PropTypes from "prop-types";
import { linkFor, linkWrapper } from "utils";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const FurtherAdvanceRow = ({
  referenceNo,
  borrowers,
  illustrationId,
  illustrationDate,
  applicationReceived,
  requestId,
}) => {
  const linkHref = linkFor("furtherAdvanceDetails", { requestId });

  return (
    <tr>
      <td className={cx("tableCell")} data-label="Reference number">
        {linkWrapper(referenceNo, linkHref)}
      </td>
      <td className={cx("tableCell")} data-label="Client names">
        {linkWrapper(borrowers, linkHref)}
      </td>
      <td className={cx("tableCell")} data-label="Illustration reference">
        {linkWrapper(illustrationId, linkHref)}
      </td>
      <td className={cx("tableCell")} data-label="Illustration date">
        {linkWrapper(illustrationDate, linkHref)}
      </td>
      <td className={cx("tableCell")} data-label="Application received">
        {linkWrapper(applicationReceived, linkHref)}
      </td>
    </tr>
  );
};

FurtherAdvanceRow.propTypes = {
  referenceNo: PropTypes.string.isRequired,
  borrowers: PropTypes.string.isRequired,
  illustrationId: PropTypes.string.isRequired,
  illustrationDate: PropTypes.string.isRequired,
  applicationReceived: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default FurtherAdvanceRow;

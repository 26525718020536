export const HEADINGS = {
  applicationStatus: [
    { label: "Status" },
    { label: "Started" },
    { label: "Days ago" },
  ],
  applicantDetails: null,
  applicationDetails: null,
  propertyDetails: null,
  documents: null,
  illustrationRequest: [
    { label: "Reference no." },
    { label: "Date generated" },
    { label: "Product" },
    { label: "Loan amount" },
    { label: "" },
  ],
  productDetails: null,
  profileDetails: null,
  EditIllustrationPropertyDetails: null,
  furtherAdvanceDetails: null,
};

import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const ApplicationDetailsRow = ({
  illustrationNo,
  accountNo,
  product,
  loanAmount,
  adviserFullName,
  adviserCommission,
}) => {
  return (
    <>
      <tr>
        <td className={cx("tableCell")}>Illustration Request</td>
        <td className={cx("tableCell")}>{illustrationNo}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Account Number</td>
        <td className={cx("tableCell")}>{accountNo}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Product</td>
        <td className={cx("tableCell")}>{product}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Loan amount</td>
        <td className={cx("tableCell")}>{loanAmount}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Adviser</td>
        <td className={cx("tableCell")}>{adviserFullName}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Adviser commission</td>
        <td className={cx("tableCell")}>{adviserCommission}</td>
      </tr>
    </>
  );
};

ApplicationDetailsRow.propTypes = {
  accountNo: PropTypes.string.isRequired,
  adviserCommission: PropTypes.string.isRequired,
  adviserFullName: PropTypes.string.isRequired,
  illustrationNo: PropTypes.string.isRequired,
  loanAmount: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
};

export default ApplicationDetailsRow;

import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Button } from "components";
import { ChevronRight, Edit } from "lib/icons";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const ClientRow = ({
  action,
  applicationNo,
  borrowers,
  hasCompleteDetails,
  illustrations,
  link,
  postcode,
  referenceNo,
}) => {
  return (
    <tr>
      <td className={cx("tableCell")} data-label="Reference number">
        {referenceNo}
      </td>
      <td className={cx("tableCell")} data-label="Borrower(s)">
        {borrowers}
      </td>
      <td className={cx("tableCell")} data-label="Postcode">
        {postcode}
      </td>
      <td className={cx("tableCell")} data-label="Illustration(s)">
        {illustrations}
      </td>
      <td className={cx("tableCell")} data-label="Application no.">
        {applicationNo}
      </td>
      <td className={cx("tableCell")} data-label="Action">
        <div className={cx("actionCellInnerWithIcon")}>
          <a
            className={cx("actionLink", `${action.disableClass}`)}
            href={action.href}
          >
            {action.label}
          </a>
          <a
            className={cx("delete", "showForLarge", `${action.disableClass}`)}
            href={action.href}
          >
            {hasCompleteDetails ? <ChevronRight /> : <Edit />}
          </a>
        </div>
      </td>
      <td className={cx("tableCell")} data-label="Action">
        <div className={cx("actionCellInner")}>
          <div className={cx("linkWrapper")}>
            <Button size="small" href={link.href}>
              {link.label}
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};

ClientRow.propTypes = {
  action: PropTypes.shape({
    disableClass: PropTypes.string,
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
  applicationNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  borrowers: PropTypes.string.isRequired,
  hasCompleteDetails: PropTypes.bool.isRequired,
  illustrations: PropTypes.number.isRequired,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
  postcode: PropTypes.string.isRequired,
  referenceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default ClientRow;

import client from "services/client";

export const furtherAdvanceDownload = async (accessToken, data) => {
  client.accessToken = accessToken;

  return await client
    .get("/further_advances/download", {
      params: {
        pdf_path: data.pdf_path,
        title: data.title,
        type: data.type,
      },
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.download = data.title;
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => error);
};

export default furtherAdvanceDownload;

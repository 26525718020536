export const CONFIGURATIONS = {
  dashboard: [
    { label: "Date received" },
    { label: "Borrowers", width: 20 },
    { label: "Postcode" },
    { label: "Reference number" },
    { label: "Status" },
    { label: "Illustration #" },
    { label: "" },
  ],
  illustration: [
    { label: "Ref no.", width: 8 },
    { label: "Date generated", width: 12 },
    { label: "Product", width: 14 },
    { label: "Interest rate (MER)" },
    { label: "Initial advance", width: 10 },
    { label: "Cash reserve facility", width: 9 },
    { label: "Inheritance guarantee" },
    { label: "Recommended", width: 14 },
    { label: "", width: 8 },
  ],
  client: [
    { label: "Client Ref", width: 10 },
    { label: "Borrower(s)" },
    { label: "Postcode", width: 10 },
    { label: "Illustration(s)", width: 14 },
    { label: "Application no.", width: 14 },
    { label: "Action", width: 20 },
    { label: "" },
  ],
  applicationsForAdmin: [
    { label: "Application no." },
    { label: "Borrower(s)" },
    { label: "Postcode", width: 10 },
    { label: "Date submitted" },
    { label: "Adviser" },
    { label: "Status", width: 8 },
    { label: "Action", width: 8 },
  ],
  applications: [
    { label: "Application no." },
    { label: "Borrower(s)" },
    { label: "Postcode", width: 10 },
    { label: "Date submitted" },
    { label: "Status", width: 8 },
    { label: "Action", width: 8 },
  ],
  rollup: [
    { label: "Year", nowrap: true },
    { label: "Balance at start of year" },
    { label: "Interest charge" },
    { label: "Fees charged during the year" },
    { label: "What you owe at the end of the year" },
  ],
  furtherAdvance: [
    { label: "Reference number" },
    { label: "Client names" },
    { label: "Illustration reference" },
    { label: "Illustration date" },
    { label: "Application received" },
  ],
};

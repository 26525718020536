import client from "services/client";

export const kfiDownloadService = async (accessToken, pdfKey, referenceNo) => {
  client.accessToken = accessToken;

  return await client
    .get(`/illustrations/${pdfKey}`, { responseType: "blob" })
    .then((response) => {
      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${referenceNo}.pdf`;
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => error);
};

export default kfiDownloadService;

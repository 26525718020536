import client from "services/client";

export const documentDownloadService = async (
  accessToken,
  documentId,
  fileName
) => {
  client.accessToken = accessToken;

  return await client
    .get(`/files/${documentId}`, { responseType: "blob" })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => error);
};

export default documentDownloadService;

import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const ApplicantDetailsRow = ({ name, dob, age }) => {
  return (
    <>
      <tr>
        <td className={cx("tableCell")}>Name</td>
        <td className={cx("tableCell")}>{name}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Date of birth</td>
        <td className={cx("tableCell")}>
          {dob} ({age})
        </td>
      </tr>
    </>
  );
};

ApplicantDetailsRow.propTypes = {
  name: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  age: PropTypes.number.isRequired,
};

export default ApplicantDetailsRow;

import classNames from "classnames/bind";
import PropTypes from "prop-types";
import { HEADINGS } from "./configurations";
import styles from "./SimpleTable.module.scss";
import {
  ApplicantDetailsRow,
  ApplicationDetailsRow,
  ApplicationStatusRow,
  DocumentRow,
  EditIllustrationPropertyDetailsRow,
  FurtherAdvanceDetailsRow,
  IllustrationRequestRow,
  PropertyDetailsRow,
  SimpleRow,
} from "./rows";
import SimpleHeading from "./heading";

const cx = classNames.bind(styles);

const SimpleTable = ({ configuration, tableData }) => {
  const tableHeadings = HEADINGS[configuration];

  return (
    <table className={cx("table")}>
      {tableHeadings && <SimpleHeading headings={tableHeadings} />}
      <tbody
        className={cx({
          headfullTable: tableHeadings,
          headlessTable: !tableHeadings,
        })}
      >
        {tableData.map((item, index) => {
          switch (configuration) {
            case "applicationStatus":
              return <ApplicationStatusRow key={index} {...item} />;

            case "applicantDetails":
              return <ApplicantDetailsRow key={index} {...item} />;

            case "applicationDetails":
              return <ApplicationDetailsRow key={index} {...item} />;

            case "propertyDetails":
              return <PropertyDetailsRow key={index} {...item} />;

            case "documents":
              return <DocumentRow key={index} {...item} />;

            case "illustrationRequest":
              return <IllustrationRequestRow key={index} {...item} />;

            case "profileDetails":
            case "productDetails":
              return <SimpleRow key={index} {...item} />;

            case "EditIllustrationPropertyDetails":
              return (
                <EditIllustrationPropertyDetailsRow key={index} {...item} />
              );

            case "furtherAdvanceDetails":
              return <FurtherAdvanceDetailsRow key={index} {...item} />;

            default:
              return null;
          }
        })}
      </tbody>
    </table>
  );
};

SimpleTable.propTypes = {
  configuration: PropTypes.oneOf(Object.keys(HEADINGS)).isRequired,
  tableData: PropTypes.array.isRequired,
};

export default SimpleTable;

import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";
import { Close, Step, Tick, Time, WarningIconFilled } from "lib/icons";

const cx = classNames.bind(styles);

const ApplicationStatusRow = ({ label, started, daysAgo, status }) => {
  const icons = {
    notStarted: <Step />,
    inProgress: <Time />,
    completed: <Tick />,
    requiresAction: <WarningIconFilled />,
    unsuccessful: <Close />,
  };

  return (
    <tr>
      <td className={cx("tableCell", status, "bold")}>
        <span className={cx("icon")}>{icons[status]} </span>
        {label}
      </td>
      <td className={cx("tableCell", status)} data-label="Date started: ">
        {started}
      </td>
      <td className={cx("tableCell", status)} data-label="Days ago: ">
        {daysAgo}
      </td>
    </tr>
  );
};

ApplicationStatusRow.propTypes = {
  label: PropTypes.string.isRequired,
  started: PropTypes.string.isRequired,
  daysAgo: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default ApplicationStatusRow;

import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const PropertyDetailsRow = ({ address, valuationInstructed, valuationRef }) => {
  const addressElement = (element) => {
    return <div key={element}>{element}</div>;
  };

  return (
    <>
      <tr>
        <td className={cx("tableCell")}>Address</td>
        <td className={cx("tableCell")}>
          <address>{address.map(addressElement)}</address>
        </td>
      </tr>
      {valuationInstructed && (
        <tr>
          <td className={cx("tableCell")}>Valuation instructed</td>
          <td className={cx("tableCell")}>{valuationInstructed}</td>
        </tr>
      )}
      {valuationRef && (
        <tr>
          <td className={cx("tableCell")}>Valuation reference</td>
          <td className={cx("tableCell")}>{valuationRef}</td>
        </tr>
      )}
    </>
  );
};

PropertyDetailsRow.propTypes = {
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  valuationInstructed: PropTypes.string,
  valuationRef: PropTypes.string,
};

export default PropertyDetailsRow;

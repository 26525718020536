import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const RollupRow = ({
  year,
  startingBalance,
  interestCharge,
  feesAmount,
  endOfYearBalance,
}) => {
  return (
    <tr>
      <td className={cx("tableCell")} data-label="Year">
        {year}
      </td>
      <td className={cx("tableCell")} data-label="Start of year balance">
        {startingBalance}
      </td>
      <td className={cx("tableCell")} data-label="Interest charge">
        {interestCharge}
      </td>
      <td className={cx("tableCell")} data-label="Fees during the year">
        {feesAmount}
      </td>
      <td className={cx("tableCell")} data-label="End of the year balance">
        {endOfYearBalance}
      </td>
    </tr>
  );
};

RollupRow.propTypes = {
  year: PropTypes.number.isRequired,
  startingBalance: PropTypes.string.isRequired,
  interestCharge: PropTypes.string.isRequired,
  feesAmount: PropTypes.string.isRequired,
  endOfYearBalance: PropTypes.string.isRequired,
};

export default RollupRow;

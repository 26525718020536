import client from "services/client";

export const offerLetterPdfDownloadService = async (
  accessToken,
  hmlAccountNumber
) => {
  client.accessToken = accessToken;

  return await client
    .get(`/mortgage_applications/${hmlAccountNumber}/offer_letter`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.download = "Offer letter.pdf";
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => error);
};

export default offerLetterPdfDownloadService;

import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";
import { Button } from "components";
import kfiDownloadService from "services/kfiDownload";
import { useSession } from "next-auth/react";
import { getAccessToken } from "utils/oauth";

const cx = classNames.bind(styles);

const IllustrationRequestRow = ({
  referenceNo,
  status,
  createdAt,
  product,
  loanAmount,
  pdfKey,
}) => {
  const { data: session } = useSession();

  const noticeColour = (status) => {
    if (status === "Active") {
      return "deepTealBackground";
    }
    return "deepBlueBackground";
  };

  const handleClick = async (e) => {
    e.preventDefault();
    await kfiDownloadService(
      await getAccessToken(session),
      pdfKey,
      referenceNo
    );
  };

  return (
    <>
      <tr>
        <td className={cx("tableCell")} data-label="Reference no: ">
          {referenceNo}
          {status && (
            <span className={cx("notice", noticeColour(status))}>{status}</span>
          )}
        </td>
        <td className={cx("tableCell")} data-label="Date generated: ">
          <time>{createdAt}</time>
        </td>
        <td className={cx("tableCell")} data-label="Product: ">
          {product}
        </td>
        <td className={cx("tableCell")} data-label="Loan amount: ">
          {loanAmount}
        </td>
        <td className={cx("tableCell")}>
          <div className={cx("actionCellInner")}>
            <div className={cx("linkWrapper")}>
              <Button size="small" onClick={handleClick}>
                Download
              </Button>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

IllustrationRequestRow.propTypes = {
  referenceNo: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  loanAmount: PropTypes.string.isRequired,
  pdfKey: PropTypes.string.isRequired,
};

export default IllustrationRequestRow;

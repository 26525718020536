import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Close, Tick, Time, WarningIconFilled } from "lib/icons";
import styles from "./rows.module.scss";
import { useSession } from "next-auth/react";
import { linkFor, linkWrapper } from "utils";
import { Button } from "components";

const cx = classNames.bind(styles);

const ApplicationsRow = ({
  actionLink,
  adviserFullName,
  date,
  isDisabled,
  link,
  name,
  postcode,
  referenceNo,
  aasmState,
  status,
}) => {
  const {
    data: {
      user: { isAdmin },
    },
  } = useSession();

  const linkHref = () => {
    if (aasmState === "Draft")
      return linkFor("applicationDetails", { referenceNo });

    return link.href;
  };

  const displayIcon = () => {
    switch (status) {
      case "Requires action":
        return <WarningIconFilled />;
      case "Withdrawn":
        return <Close />;
      case "Closed":
        return <Tick />;
      default:
        return <Time />;
    }
  };

  return (
    <tr>
      <td className={cx("tableCell")} data-label="Application no.">
        {linkWrapper(referenceNo, linkHref())}
      </td>
      <td className={cx("tableCell")} data-label="Borrower(s)">
        {linkWrapper(name, linkHref())}
      </td>
      <td className={cx("tableCell")} data-label="Postcode">
        {linkWrapper(postcode, linkHref())}
      </td>
      <td className={cx("tableCell")} data-label="Date submitted">
        {linkWrapper(date.formatted, linkHref())}
      </td>
      {isAdmin && (
        <td className={cx("tableCell")} data-label="Adviser">
          {linkWrapper(adviserFullName, linkHref())}
        </td>
      )}
      <td className={cx("tableCell", "u-nowrap")} data-label="Status">
        <div className={cx("inlineIcon", "u-text-deepBlue")}>
          {displayIcon()}
          <span>{status}</span>
        </div>
      </td>
      <td className={cx("tableCell", "actionCell")}>
        <div className={cx("actionCellInner")}>
          {status === "Requires action" && actionLink.href && (
            <span className={cx("linkWrapper")}>
              <Button
                size="small"
                href={actionLink.href}
                isDisabled={isDisabled}
              >
                {actionLink.label}
              </Button>
            </span>
          )}
        </div>
      </td>
    </tr>
  );
};

ApplicationsRow.propTypes = {
  actionLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  adviserFullName: PropTypes.string.isRequired,
  date: PropTypes.shape({
    dateTime: PropTypes.string.isRequired,
    formatted: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  referenceNo: PropTypes.string.isRequired,
  aasmState: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default ApplicationsRow;

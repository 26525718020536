import PropTypes from "prop-types";
import { Button } from "components";
import styles from "./rows.module.scss";
import classNames from "classnames/bind";
import { DASHBOARD_APPLICATION_STATUSES } from "lib/constants";

const cx = classNames.bind(styles);

const DashboardRow = ({
  actionLink,
  date,
  illustrationNo,
  isDisabled,
  name,
  postcode,
  referenceNo,
  aasmState,
}) => {
  return (
    <tr>
      <td className={cx("tableCell")} data-label="Date received">
        <time dateTime={date.dateTime}>{date.formatted}</time>
      </td>
      <td className={cx("tableCell")} data-label="Borrowers">
        {name}
      </td>
      <td className={cx("tableCell")} data-label="Postcode">
        {postcode}
      </td>
      <td className={cx("tableCell")} data-label="Reference number">
        {referenceNo}
      </td>
      <td
        className={cx("tableCell")}
        data-label="Status"
        data-testid="statusCell"
      >
        {aasmState}
      </td>
      <td className={cx("tableCell")} data-label="Illustration">
        {illustrationNo}
      </td>
      <td className={cx("tableCell", "actionCell")}>
        <div className={cx("actionCellInner")}>
          {actionLink.href && (
            <span className={cx("linkWrapper")}>
              <Button
                size="small"
                href={actionLink.href}
                isDisabled={isDisabled}
              >
                {actionLink.label}
              </Button>
            </span>
          )}
        </div>
      </td>
    </tr>
  );
};

DashboardRow.propTypes = {
  actionLink: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.shape({
    dateTime: PropTypes.string.isRequired,
    formatted: PropTypes.string.isRequired,
  }).isRequired,
  illustrationNo: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  referenceNo: PropTypes.string.isRequired,
  aasmState: PropTypes.oneOf(DASHBOARD_APPLICATION_STATUSES).isRequired,
};

export default DashboardRow;

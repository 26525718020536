import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const FurtherAdvanceDetailsRow = ({
  illustrationReference,
  referenceNo,
  product,
  loanAmount,
  directDebitAmount,
  adviserFee,
}) => {
  return (
    <>
      <tr>
        <td className={cx("tableCell")}>Illustration reference</td>
        <td className={cx("tableCell")}>{illustrationReference}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Reference number</td>
        <td className={cx("tableCell")}>{referenceNo}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Product</td>
        <td className={cx("tableCell")}>{product}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Loan amount</td>
        <td className={cx("tableCell")}>{loanAmount}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Regular direct debit amount</td>
        <td className={cx("tableCell")}>{directDebitAmount}</td>
      </tr>
      <tr>
        <td className={cx("tableCell")}>Adviser fee</td>
        <td className={cx("tableCell")}>{adviserFee}</td>
      </tr>
    </>
  );
};

FurtherAdvanceDetailsRow.propTypes = {
  illustrationReference: PropTypes.string.isRequired,
  referenceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  product: PropTypes.string.isRequired,
  loanAmount: PropTypes.string.isRequired,
  directDebitAmount: PropTypes.string.isRequired,
  adviserFee: PropTypes.string.isRequired,
};

export default FurtherAdvanceDetailsRow;

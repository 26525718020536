import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./rows.module.scss";
import documentDownloadService from "services/documentDownload";
import { useSession } from "next-auth/react";
import { getAccessToken } from "utils/oauth";
import { Button } from "components";
import kfiDownloadService from "services/kfiDownload";
import offerLetterPdfDownloadService from "services/offerLetterPdfDownload";
import furtherAdvanceDownload from "services/furtherAdvanceDownload";

const cx = classNames.bind(styles);

const DocumentRow = ({
  createdAt,
  documentId,
  href,
  size,
  sourceType,
  title,
  type,
  pdfKey,
  referenceNo,
  hmlAccountNumber,
}) => {
  const { data: session } = useSession();

  const handleClick = async (e) => {
    e.preventDefault();
    const accessToken = await getAccessToken(session);
    await documentDownloadService(accessToken, documentId, href);
  };

  const handleFurtherAdvanceDownload = async (e) => {
    e.preventDefault();
    const accessToken = await getAccessToken(session);
    const data = { pdf_path: href, title, type };
    await furtherAdvanceDownload(accessToken, data);
  };

  const handleKfiPdfDownload = async (e) => {
    e.preventDefault();
    const accessToken = await getAccessToken(session);
    await kfiDownloadService(accessToken, pdfKey, referenceNo);
  };

  const handleOfferLetterPdfDownload = async (e) => {
    e.preventDefault();
    const accessToken = await getAccessToken(session);
    await offerLetterPdfDownloadService(accessToken, hmlAccountNumber);
  };

  const renderButton = () => {
    switch (sourceType) {
      case "internalDownload":
        return (
          <Button
            icon="Document"
            onClick={handleClick}
            prependIcon
            size="small"
            theme="text"
          >
            {title} <span className={cx("fileSize")}>{size}</span>
          </Button>
        );
      case "furtherAdvanceDownload":
        return (
          <Button
            icon="Document"
            onClick={handleFurtherAdvanceDownload}
            prependIcon
            size="small"
            theme="text"
          >
            {title} <span className={cx("fileSize")}>{size}</span>
          </Button>
        );
      case "kfiPdfDownload":
        return (
          <Button
            icon="Document"
            onClick={handleKfiPdfDownload}
            prependIcon
            size="small"
            theme="text"
          >
            {title} <span className={cx("fileSize")}>{size}</span>
          </Button>
        );
      case "offerLetterPdfDownload":
        return (
          <Button
            icon="Document"
            onClick={handleOfferLetterPdfDownload}
            prependIcon
            size="small"
            theme="text"
          >
            {title} <span className={cx("fileSize")}>{size}</span>
          </Button>
        );
      case "externalDownload":
        return (
          <Button
            icon="Document"
            href={href}
            prependIcon
            size="small"
            theme="text"
          >
            {title} <span className={cx("fileSize")}>{size}</span>
          </Button>
        );
      case "externalLink":
        return (
          <Button
            icon="OutboundLink"
            href={href}
            prependIcon
            size="small"
            theme="text"
          >
            {title}
          </Button>
        );
    }
  };

  return (
    <tr className={cx("documentRow")}>
      <td className={cx("tableCell", "tableCellSmall")}>{renderButton()}</td>
      <td className={cx("tableCell", "tableCellSmall", "dateCell")}>
        <time>{createdAt}</time>
      </td>
    </tr>
  );
};

DocumentRow.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  createdAt: PropTypes.string,
  href: PropTypes.string.isRequired,
  documentId: PropTypes.number,
  sourceType: PropTypes.oneOf([
    "furtherAdvanceDownload",
    "internalDownload",
    "externalDownload",
    "externalLink",
    "kfiPdfDownload",
    "offerLetterPdfDownload",
  ]).isRequired,
  pdfKey: PropTypes.string,
  referenceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hmlAccountNumber: PropTypes.string,
  type: PropTypes.string,
};

export default DocumentRow;

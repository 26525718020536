import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Tooltip } from "components";
import { Help } from "lib/icons";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const SimpleRow = ({ title, value, tooltip }) => (
  <tr className={cx("productDetailsRow", { hasTooltip: tooltip })}>
    <th className={cx("tableCell", "title")}>{title}</th>
    <td className={cx("tableCell", "value")}>
      <span className={cx("valueWrapper")}>
        {value}
        {tooltip && (
          <Tooltip {...tooltip}>
            <Help />
          </Tooltip>
        )}
      </span>
    </td>
  </tr>
);

SimpleRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltip: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  }),
};

export default SimpleRow;

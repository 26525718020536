import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./heading.module.scss";

const cx = classNames.bind(styles);

const SimpleHeading = ({ headings }) => {
  const tableHeadingItem = (item, index) => {
    const { label, width } = item;

    return (
      <th
        key={index}
        className={cx("headingCell")}
        style={width && { width: `${width}` }}
      >
        {label}
      </th>
    );
  };

  return (
    <thead className={cx("tableHeader")}>
      <tr>{headings.map(tableHeadingItem)}</tr>
    </thead>
  );
};

SimpleHeading.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
    })
  ).isRequired,
};

export default SimpleHeading;

import Link from "next/link";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Button } from "components";
import { linkFor } from "utils";
import styles from "./rows.module.scss";

const cx = classNames.bind(styles);

const IllustrationRow = ({
  clientId,
  referenceNo,
  date,
  product,
  interestRate,
  initialAdvance,
  cashReserveFacility,
  inheritanceGuarantee,
  recommended,
  applyHref,
  applyButtonDisabled,
}) => (
  <tr>
    <td className={cx("tableCell")} data-label="Reference number">
      <Link
        href={linkFor("illustration", {
          clientId,
          illustrationId: referenceNo,
        })}
        className={cx("actionLink")}
      >
        {referenceNo}
      </Link>
    </td>
    <td className={cx("tableCell")} data-label="Date received">
      <time dateTime={date.dateTime}>{date.formatted}</time>
    </td>
    <td className={cx("tableCell")} data-label="Product">
      {product}
    </td>
    <td className={cx("tableCell")} data-label="Interest rate (MER)">
      {interestRate}
    </td>
    <td className={cx("tableCell")} data-label="Initial advance">
      {initialAdvance}
    </td>
    <td className={cx("tableCell")} data-label="Cash reserve facility">
      {cashReserveFacility}
    </td>
    <td className={cx("tableCell")} data-label="Inheritance guarantee">
      {inheritanceGuarantee}
    </td>
    <td className={cx("tableCell")} data-label="Recommended">
      {recommended}
    </td>
    <td className={cx("tableCell")}>
      <div className={cx("linkWrapper")}>
        <Button size="small" href={applyHref} isDisabled={applyButtonDisabled}>
          Apply
        </Button>
      </div>
    </td>
  </tr>
);

IllustrationRow.propTypes = {
  clientId: PropTypes.number.isRequired,
  referenceNo: PropTypes.string.isRequired,
  date: PropTypes.shape({
    dateTime: PropTypes.string,
    formatted: PropTypes.string,
  }).isRequired,
  product: PropTypes.string.isRequired,
  interestRate: PropTypes.string.isRequired,
  initialAdvance: PropTypes.string.isRequired,
  cashReserveFacility: PropTypes.string.isRequired,
  inheritanceGuarantee: PropTypes.string.isRequired,
  recommended: PropTypes.string.isRequired,
  applyHref: PropTypes.string.isRequired,
  applyButtonDisabled: PropTypes.bool.isRequired,
};

export default IllustrationRow;

import PropTypes from "prop-types";
import {
  ApplicationsRow,
  ClientRow,
  DashboardRow,
  IllustrationRow,
  RollupRow,
  FurtherAdvanceRow,
} from "./rows";
import { CONFIGURATIONS } from "./configurations";
import classNames from "classnames/bind";
import styles from "./ZebraTable.module.scss";

const cx = classNames.bind(styles);

const ZebraTable = ({ configuration, tableData }) => {
  const tableHeadings = CONFIGURATIONS[configuration];

  const tableHeadingItem = (item, index) => {
    const { label, width, nowrap } = item;

    return (
      <th
        key={index}
        className={cx("headingCell", { ["u-nowrap"]: nowrap })}
        style={width && { width: `${width}%` }}
      >
        {label}
      </th>
    );
  };

  return (
    <table className={cx("table")}>
      <thead className={cx("tableHeader")}>
        <tr>{tableHeadings.map(tableHeadingItem)}</tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => {
          switch (configuration) {
            case "dashboard": {
              return <DashboardRow key={index} {...item} />;
            }

            case "illustration": {
              return <IllustrationRow key={index} {...item} />;
            }

            case "client": {
              return <ClientRow key={index} {...item} />;
            }

            case "applications":
            case "applicationsForAdmin": {
              return <ApplicationsRow key={index} {...item} />;
            }

            case "rollup": {
              return <RollupRow key={index} {...item} />;
            }

            case "furtherAdvance": {
              return <FurtherAdvanceRow key={index} {...item} />;
            }

            default:
              return null;
          }
        })}
      </tbody>
    </table>
  );
};

ZebraTable.propTypes = {
  configuration: PropTypes.oneOf(Object.keys(CONFIGURATIONS)).isRequired,
  tableData: PropTypes.array.isRequired,
};

export default ZebraTable;
